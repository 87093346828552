import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/SEO';

import tw from 'twin.macro';
import styled from 'styled-components';
// import { graphql, useStaticQuery } from 'gatsby';

import { css } from 'styled-components/macro'; //eslint-disable-line

import { SectionHeading } from '../components/misc/Typography';
import { Container, ContentWithPaddingXl } from '../components/misc/Layouts';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const TermsAndConditions = ({ headingText = 'Terms and Conditions of Use' }) => {
  // const { page } = useStaticQuery(contactQuery);
  return (
    <Layout>
       <Seo
        title="Terms of Service"
        description="By using the Services, you represent that you have the capacity to be bound by these terms."
      />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>This policy was last modified on January 10,2022.</p>
            <p>
              These terms and conditions ("Terms and Conditions") govern your use of this website
              countmycents.com ("Website"). In these Terms and Conditions, CountMyCents Tax
              Services, is referred to as the “Company", “CountMyCents Tax Services,” “us,” or “we”.
            </p>
            <h1>1. Terms</h1>
            <p>
              By accessing CountMyCents Tax Services's web site ("Website" or "countmycents.com"),
              you are agreeing to be bound by these Terms and Conditions of Use ("Terms and
              Conditions"), all applicable laws and regulations, and agree that you are responsible
              for compliance with any applicable local laws. If you do not agree with any of these
              terms, you are prohibited from using or accessing this site. The materials,
              information, software, and content contained in and on countmycents.com’s web site
              (collectively referred to herein as the “Materials”) are protected by applicable
              copyright and trademark law. CountMyCents Tax Services may be referred to herein as
              “we”, “us”, or “countmycents.com”.
            </p>
            <h1>2. Accounts</h1>
            <p>When you create an account on our site,you agree to the following:</p>
            <p>
              You are solely responsible for your account and security and privacy of your account,
              including passwords or sensitive information attached to that account; and
            </p>
            <p>
              All personal information you provide to us through your account is up to
              date,accurate,and truthful and that you will update your personal information if it
              changes.
            </p>
            <p>
              We reserve the right to suspend or terminate your account if you are using our site
              illegally or if you violate these Terms and Conditions.
            </p>
            <h1>3. Sale of Services</h1>
            <p>The services will be paid for in full when the services are ordered. </p>
            <p>
              These Terms and Conditions apply to all the services that are displayed on our Website
              at the time you access it. All information,descriptions,or images that would provide
              about our services are as accurate as possible. However,we are not legally bound by
              such information,descriptions,or images as we cannot gurantee the accuracy of all
              sevices we provide. You agree to purchase services from our Website at your own risk.
            </p>
            <p>
              We reserve the right to modify,reject or cancel your order whenever it becomes
              necessary. If we cancel your order and have already processed your payment,we will
              give you a refund equal the amount you paid. You agree that it is your responsibility
              to monitor your payment instrument to verify receipt of any refund.
            </p>
            <h1>4. Disclaimer</h1>
            <p>
              The Materials are provided "as is". countmycents.com makes no warranties, expressed or
              implied, and hereby disclaims and negates all other warranties, including without
              limitation, implied warranties or conditions of merchantability, fitness for a
              particular purpose, or non-infringement of intellectual property or other violation of
              rights. Further, countmycents.com does not warrant or make any representations
              concerning the accuracy, likely results, or reliability of the use of the Materials or
              otherwise relating to such Materials or on any web sites linked to this web site.
            </p>
            <h1>5. Limitations</h1>
            <p>
              In no event shall countmycents.com or its suppliers be liable for any damages
              (including, without limitation, damages for loss of data or profit, or due to business
              interruption,) arising out of the use or inability to use the Materials, even if
              countmycents.com or a countmycents.com authorized representative has been notified
              orally or in writing of the possibility of such damage. Because some jurisdictions do
              not allow limitations on implied warranties, or limitations of liability for
              consequential or incidental damages, these limitations may not apply to you.
            </p>
            <h1>6. Revisions and Errata</h1>
            <p>
              The Materials could include errors, including but not limited to technical,
              typographical, or photographic. countmycents.com does not warrant that any of the
              Materials are accurate, complete, or current. countmycents.com may make changes to the
              Materials at any time without notice, however countmycents.com does not make any
              commitment to update the Materials.
            </p>
            <h1>7. Links</h1>
            <p>
              countmycents.com has not reviewed all of the sites linked to its web site and is not
              responsible for the content of any such linked web site. The inclusion of any link
              does not imply any endorsement by countmycents.com of the linked web site. Use of any
              such linked web site is at your own risk.
            </p>
            <h1>8. Site Terms of Use Modifications</h1>
            <p>
              countmycents.com may revise these Terms and Conditions of Use at any time without
              notice. By using this web site you are agreeing to be bound by the then current
              version of these Terms and Conditions of Use.
            </p>
            <h1>9. Governing Law</h1>
            <p>
              Any claim relating to countmycents.com's web site shall be governed by the laws of the
              Province of Manitoba without regard to its conflict of law provisions.
            </p>
            <p>General Terms and Conditions applicable to Use of a Web Site. </p>
            <h1>10. Refund Policy</h1>
            <p>
              The services will be fully refunded if the services are cancelled at least 24 hours
              before the services were scheduled to be provided.
            </p>
            <h1>11. Cancellation Policy</h1>
            <p>
              Without prejudice to any remedy that CountMycents Tax Services may have against you,
              CountMycents Tax Services may terminate or suspend with immediate effect and without
              notice your access to and use of this Website and your membership if:
            </p>

            <ul>
              <li>
                It reasonably believes that you have breached any of these Terms and Conditions;
              </li>
              <li>It does not receive timely payment under your subscription;</li>
              <li>
                It is unable to verify the accuracy or validity of any information provided by you;
              </li>
              <li>Or it suspects fraudulent, abusive or illegal activity by you. </li>
            </ul>
            <p>
              Should you object to any of these Terms and Conditions, or any subsequent changes to
              them, or become dissatisfied with this Website in any way, your only recourse is to
              immediately discontinue to access or use this Website.
            </p>
            <h1>12. Severability</h1>
            <p>
              If any time any of the provisions set forth in these Terms and Conditions are found to
              be inconsistent or invalid under applicable laws,those provisions will be deemed void
              and will be removed from these Terms and Conditions. All the other provisions will not
              be affected by the removal and the rest of these Terms and Conditions will still be
              considered valid.
            </p>
            <h1>13. Amendment to these Terms and Conditions</h1>
            <p>
              CountMycents Tax Services reserves the right to amend and alter these Terms and
              Conditions and any Materials or information contained on the Website at any time. In
              the event these Terms and Conditions are altered CountMycents Tax Services will post
              the amended Terms and Conditions on this Website. Changes will apply from the date of
              posting.
            </p>
            <p>
              If after the Terms and Conditions and/or the Privacy Policy have been amended or
              altered you do not agree to their terms, you must stop using this Website.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </Layout>
  );
};

export default TermsAndConditions;
